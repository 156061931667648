<template>
  <v-row class="inner-content">
    <v-col>

      <v-card class="pb-3">
        <v-card-title>
          Create RSAs
          <a class="ml-3" style="font-size: 0.8em;" href="https://docs.google.com/spreadsheets/d/1uMxSNNB6zPTYNFIG3gOT5OBmoxm4E1-lCbq7knG1wCA/edit#gid=2056071247" target="_blank"><v-icon>help</v-icon></a>
        </v-card-title>
        <v-card-text>

          <v-row>
            <v-col>
              <p>Create responsive search ads (RSAs) by selecting an account and then pasting ad details into the field below.</p>
            </v-col>
          </v-row>

          <v-form ref="form">
            <v-row>

              <v-col md="4">
                <BingAccountAutoComplete
                  v-model="selectedDestinationAccountID"
                  label="Destination account"
                  @changed="destinationAccountChanged"
                ></BingAccountAutoComplete>
              </v-col>

              <v-col md="4">
                <BingAccountAutoComplete
                  v-model="selectedBaseAccountID"
                  label="Optional: Bing base account"
                  :mandatory="false"
                  @changed="baseAccountChanged"
                ></BingAccountAutoComplete>

                <BingCampaignAutoComplete
                  v-model="selectedBaseCampaign"
                  label="Base Bing account campaign"
                  :accountID="selectedBaseAccountID"
                  :mandatory="false"
                  :showAdGroupRefresh="true"
                ></BingCampaignAutoComplete>

                <v-btn style="min-width: 165px;" class="mt-4" color="success" :loading="fillingFromBase" :disabled="!selectedDestinationAccountID || !selectedBaseCampaign" @click="fillFromBingBase()">Fill from Bing base</v-btn>
                <span class="ml-2" style="color: red;" v-show="!selectedDestinationAccountID && selectedBaseCampaign">Select destination account</span>
              </v-col>

              <v-col md="4">
                <GoogleAccountAutoComplete
                  v-model="selectedBaseGoogleAccountID"
                  label="Optional: base Google account"
                  :mandatory="false"
                  @changed="baseGoogleAccountChanged"
                ></GoogleAccountAutoComplete>

                <GoogleCampaignsMultiAutoComplete
                  v-model="selectedBaseGoogleCampaignIDs"
                  :accountID="selectedBaseGoogleAccountID"
                  label="Base Google account campaigns"
                  :mandatory="false"
                  :showAdGroupRefresh="true"
                ></GoogleCampaignsMultiAutoComplete>

                <v-btn style="min-width: 165px;" class="mt-4" color="success" :loading="fillingFromGoogle" :disabled="!selectedDestinationAccountID || selectedBaseGoogleCampaignIDs.length == 0" @click="fillFromGoogleBase()">Fill from Google base</v-btn>
                <span class="ml-2" style="color: red;" v-show="!selectedDestinationAccountID && selectedBaseGoogleCampaignIDs.length != 0">Select destination account</span>
              </v-col>

            </v-row>

            <v-row>
              <v-col md="12">
                <v-text-field style="font-size: 0.9em;" dense :placeholder="rsasHeader" disabled></v-text-field>
                <v-textarea v-model="rsasContent" clearable dense rows="6" hint="Paste RSAs here. Columns should be TAB separated, and values should be pipe (|) separated." style="background-color: lightyellow;"
                  required :rules="[v => !!v || 'RSAs are required']"  @change="rsasContentChanged">
                </v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="3">
                <v-text-field v-model="findText" label="Find" clearable></v-text-field>
              </v-col>
              <v-col md="3">
                <v-text-field v-model="replaceText" label="Replace with" clearable></v-text-field>
              </v-col>
              <v-col md="6">
                <v-btn class="mt-2" color="primary" :disabled="!findText" @click="doReplace()">Replace</v-btn>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="12">
                <v-btn color="success" :loading="validating" @click="create(true)">Validate</v-btn>
                <v-fade-transition mode="out-in">
                  <v-btn color="green darken-1" text v-show="showValid">Valid</v-btn>
                </v-fade-transition>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="12">
                <v-alert v-model="showErrorAlert" color="error" border="left" elevation="2" colored-border dismissible class="mt-4 ml-2 mr-2" icon="error" style="white-space: pre-line;">{{errorMsg}}</v-alert>
              </v-col>
            </v-row>
          </v-form>

          <v-row>
            <v-col md="12" :hidden="!validated">
              <v-simple-table class="mt-4">
                <thead>
                  <tr class="pb-0">
                    <th>Campaign</th>
                    <th>Topic</th>
                    <th>Headlines</th>
                    <th>Descriptions</th>
                    <th>Path 1</th>
                    <th>Path 2</th>
                    <th>Final URL</th>
                    <th>Status</th>
                    <th>S.AgeGroup</th>
                    <th>S.Gender</th>
                    <th># existing ad groups</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in parsedRSAs" :key="i">
                    <td>{{item.campaign}}</td>
                    <td>{{item.topic}}</td>
                    <td>{{item.headlines_str}}</td>
                    <td>{{item.descriptions_str}}</td>
                    <td>{{item.path_1}}</td>
                    <td>{{item.path_2}}</td>
                    <td>{{item.final_url}}</td>
                    <td>{{item.status}}</td>
                    <td>{{item.age_group_segments_str}}</td>
                    <td>{{item.gender_segments_str}}</td>
                    <td>{{item.ad_group_count}} per topic</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="12" :hidden="!validated">
              <v-btn class="mt-0" color="primary" :loading="creating" @click="create(false)">Create</v-btn>
              <v-fade-transition mode="out-in">
                <v-btn color="primary darken-1" text v-show="showCreated">Created</v-btn>
              </v-fade-transition>
            </v-col>
          </v-row>

        </v-card-text>
      </v-card>

    </v-col>
  </v-row>

</template>

<script>
import ccMixin from '@/mixins/contentCreator'
import formsMixin from '@/mixins/forms'

export default {
  name: 'BingRSAs',

  data () {
    return {
      rsasContent: '',
      parsedRSAs: [],
      rsasHeader: 'Campaign   Topic   Headlines 1 - 15   Descriptions 1 - 4   Path 1   Path 2   Final URL   Status   S.AgeGroup   S.Gender',
      selectedDestinationAccountID: 0,
      selectedBaseAccountID: 0,
      selectedBaseCampaign: null,
      selectedBaseGoogleAccountID: 0,
      selectedBaseGoogleCampaignIDs: [],
      fillingFromGoogle: false,
      validating: false,
      fillingFromBase: false,
      creating: false,
      lsSelectedDestinationAccount: 'contentCreator_selectedDestinationAccountBing',
      lsSelectedBaseAccount: 'contentCreator_selectedBaseAccountBing',
      lsSelectedBaseGoogleAccount: 'contentCreator_selectedBaseAccount',
      lsContent: 'contentCreator_RSAsContentBing',
      validated: false,
      showValid: false,
      showCreated: false,
      showErrorAlert: false,
      errorMsg: '',
      findText: '',
      replaceText: '',
    }
  },

  mixins: [ccMixin, formsMixin],

  computed: {
    baseEndpoint () {
      return '/a/bing/accounts/' + this.selectedBaseAccountID + '/campaigns/' + this.selectedBaseCampaign.campaign_id + '/content-creator/rsas'
    },

    baseGoogleEndpoint () {
      return '/a/bing/accounts/' + this.selectedDestinationAccountID + '/content-creator/google-rsas?google_account_id=' + this.selectedBaseGoogleAccountID + '&xper_page=2000'
    },

    destEndpoint () {
      return '/a/bing/accounts/' + this.selectedDestinationAccountID + '/content-creator/rsas'
    },
  },

  components: {
    BingAccountAutoComplete: () => import('@/components/autocompletes/BingAccountAutoComplete.vue'),
    BingCampaignAutoComplete: () => import('@/components/autocompletes/BingCampaignAutoComplete.vue'),
    GoogleAccountAutoComplete: () => import('@/components/autocompletes/GoogleAccountAutoComplete.vue'),
    GoogleCampaignsMultiAutoComplete: () => import('@/components/autocompletes/GoogleCampaignsMultiAutoComplete.vue'),
  },

  methods: {
    baseAccountChanged: function () {
      if (!this.selectedBaseAccountID) {
        this.selectedBaseAccountID = 0
      }

      this.selectedBaseCampaign = null

      localStorage.setItem(this.lsSelectedBaseAccount, JSON.stringify(this.selectedBaseAccountID))
    },

    baseGoogleAccountChanged: function () {
      if (!this.selectedBaseGoogleAccountID) {
        this.selectedBaseGoogleAccountID = 0
      }

      this.selectedBaseGoogleCampaignIDs = []

      localStorage.setItem(this.lsSelectedBaseGoogleAccount, JSON.stringify(this.selectedBaseGoogleAccountID))
    },

    create (validateOnly = true) {
      this.showErrorAlert = false
      if (!this.$refs.form.validate()) {
        return false
      }

      var action = 'creating'
      var showVar = 'showCreated'
      if (validateOnly) {
        this.validated = false
        action = 'validating'
        showVar = 'showValid'
      }

      this[action] = true

      var splitLines = this.rsasContent.split('\n')
      var obj = {
        'lines': splitLines,
        'validate_only': validateOnly
      }
      var body = JSON.stringify(obj)

      this.$http.post(this.destEndpoint, body).then(resp => {
        if (resp.data.data.error_msgs) {
          this.errorMsg = this.forms_getValidationDisplay(resp.data.data.error_msgs, 'error(s)')
          this.showErrorAlert = true
          this.parsedRSAs = []
        } else {
          if (resp.data.data.parsed_rsas) {
            this.parsedRSAs = resp.data.data.parsed_rsas
            this.validated = true
            this[showVar] = true
            this.timer = setTimeout(() => { this[showVar] = false }, this.cc_buttonDisplayMS)
          } else {
            this.parsedRSAs = []
          }
        }
      })
      .catch(errResp => {
        this.validated = false
        const description = errResp.response ? errResp.response?.data?.err_description : 'Request timed out. Try submitting RSAs in smaller chunks';

        this.$store.commit('system/setAPIError', { method: 'POST', url: this.destEndpoint, description: description, ext_msg: errResp.response?.data?.external_message })
      })
      .finally(() => this[action] = false)
    },

    destinationAccountChanged: function () {
      localStorage.setItem(this.lsSelectedDestinationAccount, JSON.stringify(this.selectedDestinationAccountID))
    },

    doReplace () {
      if (!this.findText) {
        return
      }
      if (!this.replaceText) {
        this.replaceText = ''
      }

      this.validated = false
      this.showErrorAlert = false
      this.rsasContent = this.rsasContent.replaceAll(this.findText, this.replaceText)
    },

    fillFromBingBase: function () {
      if (this.selectedBaseAccountID == 0 || !this.selectedBaseCampaign) {
        return
      }

      this.rsasContent = ''
      this.validated = false
      this.showErrorAlert = false
      this.fillingFromBase = true

      // fetch DB data for selected campaign
      var myURL = this.baseEndpoint

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {

          // put the DB data into tab-separated format
          var content = ''
          resp.data.data.forEach(v => {
            content += v.campaign
            content += '\t' + v.topic
            content += '\t' + v.headlines_str
            content += '\t' + v.descriptions_str
            content += '\t' + v.path_1
            content += '\t' + v.path_2
            content += '\t' + v.final_url
            content += '\t' + v.status
            content += '\t' + v.age_group_segments_str
            content += '\t' + v.gender_segments_str
            content += '\n'
          })
          if (content) {
            this.rsasContent = content
          } else {
            this.rsasContent = 'RSAs unavailable because ad groups for this account either do not exist, or have not yet been downloaded. Please refresh the base account ad groups above, and then try again.'
          }
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.fillingFromBase = false)
    },

    fillFromGoogleBase: function () {
      if (this.selectedBaseGoogleAccountID == 0 || this.selectedBaseGoogleCampaignIDs.length == 0) {
        return
      }

      this.rsasContent = ''
      this.validated = false
      this.showErrorAlert = false
      this.fillingFromGoogle = true

      // fetch DB data for selected campaign
      var myURL = this.baseGoogleEndpoint + '&campaign_id=' + this.selectedBaseGoogleCampaignIDs.join('|')

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {

          // put the DB data into tab-separated format
          var content = ''
          resp.data.data.forEach(v => {
            content += v.campaign
            content += '\t' + v.topic
            content += '\t' + v.headlines_str
            content += '\t' + v.descriptions_str
            content += '\t' + v.path_1
            content += '\t' + v.path_2
            content += '\t' + v.final_url
            content += '\t' + v.status
            content += '\t' + v.age_group_segments_str
            content += '\t' + v.gender_segments_str
            content += '\n'
          })
          if (content) {
            this.rsasContent = content
          } else {
            this.rsasContent = 'RSAs unavailable because ad groups for this account either do not exist, or have not yet been downloaded, or the RSAs already exist in the destination campaign.'
          }
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.fillingFromGoogle = false)
    },

    rsasContentChanged: function () {
      this.validated = false
      this.showValid = false
      this.showErrorAlert = false

     // save to local storage
      localStorage.setItem(this.lsContent, JSON.stringify(this.rsasContent))
    },
  },

  created: function () {
    // if present, use local selected accounts
    if (localStorage.getItem(this.lsSelectedDestinationAccount)) {
      this.selectedDestinationAccountID = JSON.parse(localStorage.getItem(this.lsSelectedDestinationAccount))
      this.destinationAccountChanged()
    }
    if (localStorage.getItem(this.lsSelectedBaseAccount)) {
      this.selectedBaseAccountID = JSON.parse(localStorage.getItem(this.lsSelectedBaseAccount))
      this.baseAccountChanged()
    }
    if (localStorage.getItem(this.lsSelectedBaseGoogleAccount)) {
      this.selectedBaseGoogleAccountID = JSON.parse(localStorage.getItem(this.lsSelectedBaseGoogleAccount))
      this.baseGoogleAccountChanged()
    }

    // if present, use content
    if (localStorage.getItem(this.lsContent)) {
      this.rsasContent = JSON.parse(localStorage.getItem(this.lsContent))
    }
  },
}
</script>

<style scoped>
</style>
